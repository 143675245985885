<template>
    <div>
        <app-banner :image="bannerImage">
            <div class="d-flex flex-column align-center justify-center fill-height fill-width">
                <img alt="logo" class="mx-auto d-block mb-6 logo" src="@/assets/logo-dark.png" @click="open('https://australianphotographicprize.com.au', '_blank')" />
                <h1 class="text-center white--text">Australian Photographic Prize Winners</h1>
            </div>
        </app-banner>

        <v-progress-linear :indeterminate="loading" color="primary"></v-progress-linear>

        <v-container fluid class="pa-0 grey">
            <v-container class="py-7">
                <v-row>
                    <v-col class="px-5" cols="12" sm="4">
                        <v-autocomplete v-model="filter.types" multiple solo hide-details placeholder="Competition Type" :items="types" item-text="title" item-value="value"></v-autocomplete>
                    </v-col>
                    <v-col class="px-5" cols="12" sm="4">
                        <v-autocomplete v-model="filter.mediums" multiple solo hide-details placeholder="Medium" :items="mediums" item-text="title" item-value="value"></v-autocomplete>
                    </v-col>
                    <v-col class="px-5" cols="12" sm="4">
                        <v-autocomplete v-model="filter.years" multiple solo hide-details placeholder="Year" :items="years" item-text="title" item-value="value"></v-autocomplete>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <v-container class="py-7 px-4">
            <v-row>
                <v-col cols="12">
                    <h1 class="text-center">Choose a Competition</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="6" md="4" lg="3" v-for="competition in filtered" :key="competition._id">
                    <app-tile :item="competition" @clicked="clicked"></app-tile>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Competition from "@/modules/fluro/competition";

import AppBanner from "@/components/app-banner.vue";
import AppTile from "@/components/app-tile.vue";

export default {
    name: "competition",

    meta: {
        title: "Competitions",
    },

    components: {
        AppBanner,
        AppTile,
    },

    data() {
        return {
            loading: false,
            competitions: [],
            definition: {},
            filter: {
                types: [],
                mediums: [],
                years: [],
            },
            bannerImage: {
                path: require("@/assets/bg.jpg"),
            },
        };
    },

    computed: {
        types() {
            let type = this.definition?.fields?.find((field) => field.key == "judgingType");
            return type?.options || [];
        },
        mediums() {
            let type = this.definition?.fields?.find((field) => field.key == "type");
            return type?.options || [];
        },
        years() {
            let years = this.competitions
                // map the years
                ?.map((competition) => {
                    let { purchaseStart, purchaseEnd, submissionStart, submissionEnd, judgingStart, judgingEnd } = this.getYears(competition);
                    let array = [purchaseStart, purchaseEnd, submissionStart, submissionEnd, judgingStart, judgingEnd];
                    return [...new Set(array)];
                })
                // flatten array of arrays
                .flat()
                // filter out null values
                .filter((year) => year);

            return [...new Set(years)] || [];
        },
        filtered() {
            let competitions = this.competitions;

            let { types, mediums, years } = this.filter;

            if (types?.length) {
                competitions = competitions.filter((competition) => {
                    return types.some((type) => competition?.data?.judgingType == type);
                });
            }

            if (mediums?.length) {
                competitions = competitions.filter((competition) => {
                    return mediums.some((medium) => competition?.data?.type == medium);
                });
            }

            if (years?.length) {
                competitions = competitions.filter((competition) => {
                    return years.some((year) => {
                        let { purchaseStart, purchaseEnd, submissionStart, submissionEnd, judgingStart, judgingEnd } = this.getYears(competition);
                        let array = [purchaseStart, purchaseEnd, submissionStart, submissionEnd, judgingStart, judgingEnd];
                        return array.includes(year);
                    });
                });
            }

            return competitions;
        },
    },

    methods: {
        async init() {
            this.loading = true;

            let params = {
                _type: "article",
                status: {
                    $in: ["active", "archived"],
                },
                "data.judging.closed": true,
            };

            this.definition = await this.$fluro.api.get("/defined/type/competition").then(({ data }) => data?.definition);
            console.log("DEFINITION", this.definition);

            this.competitions = await Competition.query(params).then(({ data }) => data);
            console.log("COMPETITIONS", this.competitions);

            this.loading = false;
        },
        clicked(item) {
            this.$router.push({ name: "competition-id", params: { id: item._id } });
        },
        getYears(competition) {
            let purchaseStart = new Date(competition?.data?.purchase?.startDate).getFullYear();
            let purchaseEnd = new Date(competition?.data?.purchase?.endDate).getFullYear();
            let submissionStart = new Date(competition?.data?.submission?.startDate).getFullYear();
            let submissionEnd = new Date(competition?.data?.submission?.endDate).getFullYear();
            let judgingStart = new Date(competition?.data?.judging?.startDate).getFullYear();
            let judgingEnd = new Date(competition?.data?.judging?.endDate).getFullYear();

            return { purchaseStart, purchaseEnd, submissionStart, submissionEnd, judgingStart, judgingEnd };
        },
        open(url, target) {
            target = target || "_self";
            window.open(url, target);
        },
    },

    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss" scoped>
.logo {
    width: 15vw;
    max-width: 120px;
    cursor: pointer;
}
</style>
