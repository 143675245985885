<template>
    <div class="app-tile">
        <v-card class="app-tile-inner rounded-lg pa-2 overflow-hidden" elevation="0" @click="clicked">
            <app-image :src="image"></app-image>

            <div class="text-ellipsis">
                <b>{{ title }}</b>
                <p class="ma-0 caption text--disabled" v-if="subtitle">{{ subtitle }}</p>
            </div>
        </v-card>

        <div class="app-tile-slot">
            <slot name="absolute" />
        </div>
    </div>
</template>

<script>
import AppImage from "@/components/app-image.vue";

export default {
    name: "app-tile",

    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    components: {
        AppImage,
    },

    computed: {
        image() {
            if (this.item?.data?.image?._id) {
                return this.$fluro.asset.imageUrl(this.item.data.image?._id || this.item.data.image, 300, 300);
            } else {
                return this.getThumbnailFromEmbedUrl(this.item?.data.videoSourceFull);
            }
        },
        title() {
            return this.item.displayTitle || this.item.title;
        },
        subtitle() {
            return this.item.subtitle;
        },
    },

    methods: {
        getThumbnailFromEmbedUrl(embedUrl) {
            const regex = /^https:\/\/www\.youtube\.com\/embed\/(.*)$/;
            const match = embedUrl.match(regex);
            if (match) {
                return `https://img.youtube.com/vi/${match[1]}/hqdefault.jpg`;
            }
            // If URL is not in expected format, return null
            return null;
        },
        clicked() {
            this.$emit("clicked", this.item);
        },
    },
};
</script>

<style lang="scss">
.app-tile {
    position: relative;
}

.app-tile-slot {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}
</style>
