<template>
    <div class="app-banner grey" :style="imageStyles">
        <div class="app-banner-inner">
            <slot name="default" />
        </div>
    </div>
</template>

<script>
export default {
    name: "app-banner",

    props: {
        image: {
            type: Object,
            default() {
                return {};
            },
        },
        position: {
            type: String,
            default: "",
        },
    },

    computed: {
        imageStyles() {
            let styles = {};

            if (this.image?.path) {
                if (this.image?.fluro) {
                    styles.backgroundImage = `url(${this.$fluro.asset.imageUrl(this.image.path)})`;
                } else {
                    styles.backgroundImage = `url(${this.image.path})`;
                }

                if (this.position?.length && this.$vuetify.breakpoint.smAndUp) {
                    styles.backgroundPosition = this.position;
                }
            }

            return styles;
        },
    },
};
</script>

<style lang="scss">
.app-banner {
    padding-top: 240px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    @media (min-width: 960px) {
        padding-top: 25%;
    }

    .app-banner-inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
    }
}
</style>
